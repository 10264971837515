<!--
* @Author: tianzl
* @Date: 2023-11-08 11:09:52
* @LastEditors: tianzl
* @LastEditTime: 2023-11-08 11:09:52
* @Description: 试驾车管理
 -->
 <template>
  <div class="car-manage">
    <van-tabs v-model="type" class="tabs1" @change="onRefresh">
      <van-tab
        v-for="item in tabs"
        :key="item.value"
        :title="item.name"
        :name="item.value"
      >
      </van-tab>
    </van-tabs>
    <List ref="list" :fetchApi="fetchApi">
      <template v-slot:default="{ item, index }">
        <CarItem :item-data="item" :type="type" @go="(type)=>handleGo(type,item)" @upDate="onRefresh"/>
      </template>
    </List>
  </div>
 </template>
<script>
import List from '@/components/baseList/list.vue'
import CarItem from './components/car-item.vue'
import loading from '@/utils/loading'
import CarManageServices from '@/services/carManageService.js'
export default {
  components:{ CarItem,List },
  data(){
    return {
      type:1,
      tabs:[
        {
          name: this.$t('可用车辆'),
          value: 1,
        },
        {
          name: this.$t('不可用车辆'),
          value: 0,
        }
      ],
    }
  },
  methods:{
    fetchApi(obj={}){
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        query:{
          isRetire: '11041002',
          status: this.type
        }
      }
      loading.showLoading()
      return CarManageServices.getList(params).then(res => {
        loading.hideLoading()
        return res
      })
    },
    // 刷新线索数据
    onRefresh() {
      try {
        this.$refs.list.onRefresh()
      } catch (error) {

      }
    },
    handleGo(type,item){
      const { vinCode,carNumber,id }=item
      const typeMap = {
        operate:'carOperate',
        schedule:'carScheduling',
        detail:'carManageDetail',
      }
      this.$router.push({
        name: typeMap[type],
        query:{
          id,
          vinCode,
          carNumber
        }
      })
    }
  }
}
</script>
